import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  

const CandidateDashboard = () => {
    const { auth } = useContext(AuthContext);
    const [user, setUser] = useState(null);
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();  

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const res = await axios.get('https://call4naukari.onrender.com/api/auth/me');
                setUser(res.data);  
            } catch (err) {
                console.error(err);
            }
        };

        if (auth.token) {
            fetchUser();  
        }
    }, [auth.token]);

    if (!auth.token || !user) {
        return <div>Loading...</div>;  
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="container mx-auto py-12 px-6">
                <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">Candidate Dashboard</h1>
                <span className="text-gray-800">Welcome, {user.name}!</span> 
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
                    {/* Profile card with navigation to ProfileForm */}
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold mb-4">Profile</h2>
                        <p className="text-gray-600">View and edit your profile details.</p>
                        <button 
                            className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600"
                            onClick={() => navigate('/profile-form')}  // Navigate to Profile Form
                        >
                            View Profile
                        </button>
                    </div>

                    {/* Other dashboard cards */}
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold mb-4">Jobs</h2>
                        <p className="text-gray-600">Search and apply for jobs</p>
                        <button className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600"
                        onClick={() => navigate('/job-list')}>
                            View Jobs
                        </button>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold mb-4">Job Applications</h2>
                        <p className="text-gray-600">Track your submitted job applications.</p>
                        <button className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600"
                        onClick={() => navigate('/my-applications')}>
                            View Applications
                        </button>
                    </div>
                </div>

                {/* Logout button */}
                <div className="flex justify-center mt-8">
                    <button
                        onClick={logout}
                        className="bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-red-700 transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-300"
                    >
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CandidateDashboard;
