import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const ProfileForm = () => {
    const [formData, setFormData] = useState({
        dateOfBirth: '',
        gender: '',
        permanentAddress: { country: 'a', state: '', city: '', address: '' },
        currentAddress: { country: 'a', state: '', city: '', address: '' },
        preferredLocation: '',
        documents: {
            aadharCard: null,
            panCard: null,
            },
        technologies: '',
        keySkills: '',
        totalExperience: '',
        relevantExperience: '',
        currentWorkingStatus: false,
        currentCompany: '',
        currentCTC: '',
        currentShiftTime: '',
        workModel: 'Onsite',
        preferredOpportunityType: '',
        preferredWorkModel: 'Onsite',
        availabilityNoticePeriod: '',
        preferredShiftTime: '',
        preferredSalary: '',
        salaryNegotiable: false,
        bgvEmployment: false,
        willingToRelocate: false,
        willingToTravel: false,
        linkedinURL: '',
        languagesKnown: '',
        referralSource: '',
        messageToHiringManager: ''
    });

    // Fetch existing profile data if available
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('token');
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                };
                const res = await axios.get('https://call4naukari.onrender.com/api/candidate/profile', config);
                if (res.data) {
                    setFormData({ ...formData, ...res.data });
                }
            } catch (err) {
                console.error(err);
                toast.error('Failed to fetch profile data!');
            }
        };

        fetchProfileData();
    }, []); // Empty dependency array means this runs once on component mount

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value || '' 
        });
    };

    const handleAddressChange = (addressType, field, value) => {
        setFormData((prevState) => ({
          ...prevState,
          [addressType]: { ...prevState[addressType], [field]: value },
        }));
      };

    // Handle file input change
const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
        if (['tenth', 'twelfth', 'graduation'].includes(name)) {
            // Handle educationalDocs separately
            setFormData((prevState) => ({
                ...prevState,
                documents: {
                    ...prevState.documents,
                    educationalDocs: { ...prevState.documents.educationalDocs, [name]: file },
                },
            }));
        } else {
            // Handle other document fields
            setFormData((prevState) => ({
                ...prevState,
                documents: { ...prevState.documents, [name]: file },
            }));
        }
    }
};

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Candidate Data:", formData);

        // Extract the documents part to upload separately
    const { documents, ...restOfformData } = formData;
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };
            await axios.post('https://call4naukari.onrender.com/api/candidate/profile', restOfformData, config);
             // 2. If there are files, upload the documents
        if (documents && Object.keys(documents).length > 0) {
            await uploadFiles(documents);  // Call the function to handle file upload
        }
            toast.success('Profile submitted successfully!');
        } catch (err) {
            console.error(err);
            toast.error('Profile submission failed!');
        }
    };

    // Function to upload files
const uploadFiles = async (documents) => {
    const formData = new FormData();

    // Append document files to FormData
    Object.keys(documents).forEach((key) => {
        const file = documents[key];
        if (key === 'educationalDocs' && file) {
            // Handle educational documents separately
            Object.keys(file).forEach((eduKey) => {
                const eduFile = file[eduKey];
                if (eduFile) {
                    formData.append(`educationalDocs[${eduKey}]`, eduFile);  // Add each educational doc file
                }
            });
        } else if (file) {
            formData.append(key, file);  // Add other document files like aadharCard, panCard, etc.
        }
    });

    try {
        // Send the formData with the files to the upload endpoint
        await axios.post('https://call4naukari.onrender.com/api/candidate/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        alert('Files uploaded successfully.');
    } catch (err) {
        console.error("Error response:", err.response ? err.response.data : err);
        alert('Error uploading files. Please check the console for details.');
    }
};

    const handleClear = () => {
        setFormData({
            dateOfBirth: '',
            gender: '',
            preferredLocation: '',
            technologies: '',
            keySkills: '',
            totalExperience: '',
            relevantExperience: '',
            currentWorkingStatus: false,
            currentCompany: '',
            currentCTC: '',
            currentShiftTime: '',
            workModel: '',
            preferredOpportunityType: '',
            preferredWorkModel: '',
            availabilityNoticePeriod: '',
            preferredShiftTime: '',
            preferredSalary: '',
            salaryNegotiable: false,
            bgvEmployment: false,
            willingToRelocate: false,
            willingToTravel: false,
            linkedinURL: '',
            languagesKnown: '',
            referralSource: '',
            messageToHiringManager: ''
        });
    };

    return (
        <div className="container mx-auto py-12 px-6">
            <h1 className="text-4xl font-bold text-center text-gray-800 mb-6">Candidate Profile Form</h1>
            <form onSubmit={handleSubmit} className="max-w-4xl mx-auto bg-white p-8 shadow-lg rounded-lg">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Date of Birth:</label>
                        <input
                            type="date"
                            name="dateOfBirth"
                            value={formData.dateOfBirth || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                            required
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Gender:</label>
                        <select
                            name="gender"
                            value={formData.gender || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                            required
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                    <div className="flex flex-col">
            <label className="mb-2 text-gray-700">Permanent Address:</label>
            
            {/* Country */}
            <textarea
                name="country"
                value={formData.permanentAddress.country}
                onChange={(e) => handleAddressChange('permanentAddress', 'country', e.target.value)}
                placeholder="Country"
                className="border rounded-md px-4 py-2 mb-2"
                required
            ></textarea>

            {/* State */}
            <textarea
                name="state"
                value={formData.permanentAddress.state}
                onChange={(e) => handleAddressChange('permanentAddress', 'state', e.target.value)}
                placeholder="State"
                className="border rounded-md px-4 py-2 mb-2"
                required
            ></textarea>

            {/* City */}
            <textarea
                name="city"
                value={formData.permanentAddress.city}
                onChange={(e) => handleAddressChange('permanentAddress', 'city', e.target.value)}
                placeholder="City"
                className="border rounded-md px-4 py-2 mb-2"
                required
            ></textarea>

            {/* Address */}
            <textarea
                name="address"
                value={formData.permanentAddress.address}
                onChange={(e) => handleAddressChange('permanentAddress', 'address', e.target.value)}
                placeholder="Address"
                className="border rounded-md px-4 py-2"
                required
            ></textarea>
        </div>

        <div className="flex flex-col">
  <label className="mb-2 text-gray-700">Current Address:</label>
  
  <input
    name="country"
    value={formData.currentAddress.country || ''}
    onChange={(e) => handleAddressChange('currentAddress', 'country', e.target.value)}
    placeholder="Country"
    className="border rounded-md px-4 py-2 mb-2"
  />

  <input
    name="state"
    value={formData.currentAddress.state || ''}
    onChange={(e) => handleAddressChange('currentAddress', 'state', e.target.value)}
    placeholder="State"
    className="border rounded-md px-4 py-2 mb-2"
  />

  <input
    name="city"
    value={formData.currentAddress.city || ''}
    onChange={(e) => handleAddressChange('currentAddress', 'city', e.target.value)}
    placeholder="City"
    className="border rounded-md px-4 py-2 mb-2"
  />

  <textarea
    name="address"
    value={formData.currentAddress.address || ''}
    onChange={(e) => handleAddressChange('currentAddress', 'address', e.target.value)}
    placeholder="Detailed Address"
    className="border rounded-md px-4 py-2"
  ></textarea>
</div>


                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Preferred Location:</label>
                        <input
                            type="text"
                            name="preferredLocation"
                            value={formData.preferredLocation || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Technologies:</label>
                        <input
                            type="text"
                            name="technologies"
                            value={formData.technologies || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Key Skills:</label>
                        <input
                            type="text"
                            name="keySkills"
                            value={formData.keySkills || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Total Experience (Years):</label>
                        <input
                            type="number"
                            name="totalExperience"
                            value={formData.totalExperience || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Relevant Experience (Years):</label>
                        <input
                            type="number"
                            name="relevantExperience"
                            value={formData.relevantExperience || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Are you currently working?</label>
                        <input
                            type="checkbox"
                            name="currentWorkingStatus"
                            checked={formData.currentWorkingStatus} // Controlled checkbox
                            onChange={handleChange}
                            className="w-6 h-6"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Current Company:</label>
                        <input
                            type="text"
                            name="currentCompany"
                            value={formData.currentCompany || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Current CTC:</label>
                        <input
                            type="number"
                            name="currentCTC"
                            value={formData.currentCTC || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Current Shift Time:</label>
                        <input
                            type="text"
                            name="currentShiftTime"
                            value={formData.currentShiftTime || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Work Model:</label>
                        <input
                            type="text"
                            name="workModel"
                            value={formData.workModel || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Preferred Opportunity Type:</label>
                        <input
                            type="text"
                            name="preferredOpportunityType"
                            value={formData.preferredOpportunityType || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Preferred Work Model:</label>
                        <input
                            type="text"
                            name="preferredWorkModel"
                            value={formData.preferredWorkModel || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Availability Notice Period:</label>
                        <input
                            type="number"
                            name="availabilityNoticePeriod"
                            value={formData.availabilityNoticePeriod || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Preferred Shift Time:</label>
                        <input
                            type="text"
                            name="preferredShiftTime"
                            value={formData.preferredShiftTime || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Preferred Salary:</label>
                        <input
                            type="number"
                            name="preferredSalary"
                            value={formData.preferredSalary || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Is salary negotiable?</label>
                        <input
                            type="checkbox"
                            name="salaryNegotiable"
                            checked={formData.salaryNegotiable} // Controlled checkbox
                            onChange={handleChange}
                            className="w-6 h-6"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Background Verification Employment:</label>
                        <input
                            type="checkbox"
                            name="bgvEmployment"
                            checked={formData.bgvEmployment} // Controlled checkbox
                            onChange={handleChange}
                            className="w-6 h-6"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Willing to Relocate?</label>
                        <input
                            type="checkbox"
                            name="willingToRelocate"
                            checked={formData.willingToRelocate} // Controlled checkbox
                            onChange={handleChange}
                            className="w-6 h-6"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Willing to Travel?</label>
                        <input
                            type="checkbox"
                            name="willingToTravel"
                            checked={formData.willingToTravel} // Controlled checkbox
                            onChange={handleChange}
                            className="w-6 h-6"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">LinkedIn URL:</label>
                        <input
                            type="text"
                            name="linkedinURL"
                            value={formData.linkedinURL || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Languages Known:</label>
                        <input
                            type="text"
                            name="languagesKnown"
                            value={formData.languagesKnown || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">AadharCard</label>
                        <input
                            type="file"
                            name="aadharCard"
                            onChange={handleFileChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">PAN Card</label>
                        <input
                            type="file"
                            name="panCard"
                            onChange={handleFileChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Referral Source:</label>
                        <input
                            type="text"
                            name="referralSource"
                            value={formData.referralSource || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label className="mb-2 text-gray-700">Message to Hiring Manager:</label>
                        <textarea
                            name="messageToHiringManager"
                            value={formData.messageToHiringManager || ''} 
                            onChange={handleChange}
                            className="border rounded-md px-4 py-2"
                        ></textarea>
                    </div>
                </div>

                <div className="flex justify-between mt-6">
                    <button type="button" onClick={handleClear} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md">Clear</button>
                    <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded-md">Submit</button>
                </div>
            </form>
        </div>
    );
};

export default ProfileForm;
