import React, { useContext } from 'react';
import { Link } from 'react-router-dom'; 
import { AuthContext } from '../context/AuthContext'; 

const Navbar = () => {
  const { auth } = useContext(AuthContext); 
  const { logout } = useContext(AuthContext);

  return (
    <nav className="bg-blue-600 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-xl font-bold">HRMS</div>
        <ul className="flex space-x-4">
          <li className="hover:text-gray-300">
            <Link to="/job-list">Jobs</Link>
          </li>
          <li className="hover:text-gray-300">
            <Link to="/companies">Companies</Link>
          </li>
          <li className="hover:text-gray-300">
            <Link to="/services">Services</Link>
          </li>
          <li className="hover:text-gray-300">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
        <div className="flex space-x-4">
          {!auth.token && ( // Only show buttons if the user is not logged in
            <>
              <Link
                to="/login"
                className="bg-white text-blue-600 px-4 py-2 rounded hover:bg-gray-100"
              >
                Login
              </Link>
              <Link
                to="/register"
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                Register
              </Link>
            </>
          )}
          {auth.token && ( // Show logout button if the user is logged in
            
             <button
             onClick={logout}
             className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
         >
             Logout
         </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
