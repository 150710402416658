import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const PrivateRoute = ({ children,requiredRole  }) => {
  const { auth } = useContext(AuthContext);
  
  if (auth.loading) {
    return <div>Loading...</div>;
  }

  if (!auth.isAuthenticated) {
    return <Navigate to="/login" />;
  }

    // Check if the user role matches the required role, wait until the user object is ready
    if (requiredRole && (!auth.user || auth.user.role !== requiredRole)) {
      return <Navigate to="/403" />;
    }

  return children;
};

export default PrivateRoute;
