import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { CircularProgress, Typography, Card, CardContent, Button } from "@mui/material";
import { format } from 'date-fns';

const MyApplications = () => {
  const { auth } = useContext(AuthContext);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        setLoading(true);
        const response = await axios.get("https://besto-hrms.onrender.com/api/jobs/my-applications");
        setApplications(response.data);
        setLoading(false);
      } catch (error) {
        setError("Error fetching applications.");
        setLoading(false);
      }
    };

    // Only fetch applications if the user is authenticated
    if (auth.isAuthenticated) {
      fetchApplications();
    }
  }, [auth]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography variant="body1" color="error">{error}</Typography>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Typography variant="h4" gutterBottom className="text-blue-800">
        My Job Applications
      </Typography>
      {applications.length > 0 ? (
        applications.map((application) => (
          <Card key={application._id} className="shadow-md my-4">
            <CardContent>
              <Typography variant="h6">
                Job Title: {application.jobId.title}
              </Typography>
              <Typography variant="body2" className="mt-2">
                Applied At: {new Date(application.appliedAt).toLocaleString()}
              </Typography>

              {application.resume && (
                <a href={`https://besto-hrms.onrender.com/${application.resume}`} target="_blank" rel="noopener noreferrer">
                  <Button variant="outlined" className="mt-4">
                    View Resume
                  </Button>
                </a>
              )}
              
              <Typography variant="body2" className="mt-2">
                Status: {application.interview?.status || "Under Review"}
              </Typography>
              <Typography variant="body2" className="mt-2">
                Scheduled Date: {application.interview?.scheduledDate 
                  ? format(new Date(application.interview.scheduledDate), 'PPpp') 
                  : "Under Review"}
              </Typography>
              <Typography variant="body2" className="mt-2">
                Outcome: {application.interview?.outcome || "Not scheduled"}
              </Typography>
              <Typography variant="body2" className="mt-2">
                Feedback: {application.interview?.outcomeNotes || "..."}
              </Typography>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography variant="body1" color="textSecondary">
          No job applications found.
        </Typography>
      )}
    </div>
  );
};

export default MyApplications;
