import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Typography,
  Button,
  CircularProgress,
  Card,
  CardContent,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const JobDetail = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [resume, setResume] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [formError, setFormError] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(
          `https://besto-hrms.onrender.com/api/jobs/jobs/${id}`
        );
        setJob(response.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to load job details");
        setLoading(false);
      }
    };

    const fetchUserStatus = async () => {
      try {
        const response = await axios.get(
          "https://call4naukari.onrender.com/api/candidate/status"
        );
        console.log(response.data.status)
        setUserStatus(response.data.status); 
      } catch (error) {
        console.error("Error fetching user status", error);
      }
    };

    fetchJobDetails();
    fetchUserStatus(); // Fetch the user's status when the component mounts
  }, [id]);

  const handleFileChange = (e) => {
    setResume(e.target.files[0]);
  };

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = {
      questionId: job.questions[index]._id,
      answerText: value,
    };
    setAnswers(newAnswers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus(null);
    setFormError(null);

    if (!resume) {
      setFormError("Please upload a resume.");
      return;
    }

    const formData = new FormData();
    formData.append("resume", resume);
    formData.append("jobId", id);
    formData.append("answers", JSON.stringify(answers));

    try {
      await axios.post(
        "https://besto-hrms.onrender.com/api/jobs/job-applications",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setSubmitStatus("Application submitted successfully!");
      navigate("/job-list");
    } catch (error) {
      setSubmitStatus("Failed to submit application.");
      console.error("Error submitting application", error);
    }
  };

  if (loading) return <CircularProgress />;
  if (error)
    return (
      <Typography variant="body1" color="error">
        {error}
      </Typography>
    );

  return (
    <div className="container mx-auto px-4 py-8">
      {job ? (
        <Card className="shadow-md hover:shadow-lg transition-all duration-300">
          <CardContent>
            <Typography variant="h4" gutterBottom className="text-blue-800">
              {job.title}
            </Typography>
            <Typography variant="body1" className="mt-2">
              {job.description}
            </Typography>

            {/* Display job questions */}
            {job.questions && job.questions.length > 0 && (
              <div className="mt-4">
                <Typography variant="h6">Job Questions</Typography>
                {job.questions.map((question, index) => (
                  <TextField
                    key={question._id}
                    label={question.questionText}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={answers[index]?.answerText || ""}
                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                    disabled={
                      userStatus !== "Verified" &&
                      userStatus !== "Screening Done"
                    }
                  />
                ))}
              </div>
            )}

            {/* Application Form */}
            <form onSubmit={handleSubmit} className="mt-6">
              <div className="mb-4">
                <label
                  htmlFor="resume"
                  className="block text-sm font-medium text-gray-700"
                >
                  Upload Resume (PDF only)
                </label>
                <TextField
                  type="file"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  inputProps={{ accept: ".pdf" }} // Only accept PDF files
                  onChange={handleFileChange}
                  disabled={
                    userStatus !== "Verified" && userStatus !== "Screening Done"
                  }
                />
              </div>

              {formError && <Typography color="error">{formError}</Typography>}
              {submitStatus && (
                <Typography
                  color={
                    submitStatus.includes("successfully") ? "primary" : "error"
                  }
                >
                  {submitStatus}
                </Typography>
              )}

              {userStatus !== "Verified" && userStatus !== "Screening Done" && (
                <Typography color="error" className="mt-2">
                  Only Verified profiles are allowed to apply.
                </Typography>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="mt-4"
                fullWidth
                disabled={
                  userStatus !== "Verified" && userStatus !== "Screening Done"
                } 
              >
                Submit Application
              </Button>
            </form>
          </CardContent>
        </Card>
      ) : (
        <Typography variant="body1" color="textSecondary">
          Job not found.
        </Typography>
      )}
    </div>
  );
};

export default JobDetail;
