import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Forbidden from "./components/Forbidden";
import Homepage from "./components/Homepage";
import Register from "./components/Register";
import Login from "./components/Login";
import CandidateDashboard from "./components/Candidate/CandidateDashboard";
import ProfileForm from "./components/Candidate/ProfileForm";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import "./App.css";
import JobList from "./components/JobList";
import JobDetail from "./components/JobDetail";
import { ToastContainer } from 'react-toastify';
import MyApplications from "./components/MyApplications";
import 'react-toastify/dist/ReactToastify.css';


const App = () => {
  return (
    <Router>
      <Navbar />  
      <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/Candidate-dashboard" element={<PrivateRoute requiredRole="Candidate"><CandidateDashboard /></PrivateRoute>} />
      <Route path="/profile-form" element={<PrivateRoute requiredRole="Candidate"><ProfileForm /></PrivateRoute>} />  
      <Route path="/403" element={<Forbidden />} />
      <Route path="/job-list" element={<JobList />} />
        <Route path="/job/:id" element={<JobDetail />} />
        <Route path="/my-applications" element={<MyApplications />} />
      </Routes>
      <Footer />
      <ToastContainer />
    </Router>
  );
};

export default App;
