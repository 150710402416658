import React from "react";

const Forbidden = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-4xl font-extrabold text-red-600 mb-4">403 Forbidden</h1>
        <p className="text-lg text-gray-700 mb-6">You do not have permission to access this page.</p>
        <a href="/" className="text-blue-500 hover:text-blue-600 font-semibold">
          Go back to Home
        </a>
      </div>
    </div>
  );
};

export default Forbidden;
