import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { Button, Typography, Card, CardContent, CardActions } from "@mui/material";

const JobList = () => {
  const { auth } = useContext(AuthContext);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get("https://besto-hrms.onrender.com/api/jobs/job-posts");
        const filteredJobs = response.data.filter(
          (job) => job.status === "Active" 
        );

        if (auth.isAuthenticated && auth.user && auth.user.role) {
          const visibleJobs = filteredJobs.filter((job) =>
            job.visibilityRoles.includes(auth.user.role)
          );
          setJobs(visibleJobs);
        } else {
          const candidateJobs = filteredJobs.filter((job) =>
            job.visibilityRoles.includes("Candidate")
          );
          setJobs(candidateJobs);
        }
      } catch (error) {
        console.error("Error fetching jobs", error);
      }
    };

    fetchJobs();
  }, [auth]);

  return (
    <div className="container mx-auto px-4 py-8">
      <Typography variant="h4" gutterBottom className="text-center text-gray-800">
        Available Jobs
      </Typography>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {jobs.length > 0 ? (
          jobs.map((job) => (
            <Card key={job._id} className="shadow-md hover:shadow-lg transition-all duration-300">
              <CardContent>
                <Typography variant="h6" component="div" className="text-blue-800">
                  <Link to={`/job/${job._id}`} className="hover:underline">
                    {job.title}
                  </Link>
                </Typography>
                <Typography variant="body2" color="textSecondary" className="mt-2">
                  {job.description.substring(0, 100)}...
                </Typography>
              </CardContent>
              <CardActions className="flex flex-col items-start">
                <Link to={`/job/${job._id}`}>
                  <Button variant="contained" color="primary" className="w-full">
                    View Details
                  </Button>
                </Link>
              </CardActions>
            </Card>
          ))
        ) : (
          <Typography variant="body1" className="col-span-3 text-center text-gray-600">
            No jobs available
          </Typography>
        )}
      </div>
    </div>
  );
};

export default JobList;
